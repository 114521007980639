module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-PPZTJSXJ","cookieName":"gatsby-gdpr-gtm","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Clear Safety Services Ltd","short_name":"Clear Safety Services Ltd","start_url":"/","background_color":"#53BAA3","theme_color":"#53BAA3","display":"minimal-ui","icon":"src/images/favicon.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ace5b2c74f85360d09fadc5286e72850"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"MC5aU1ZQM3hBQUFDUUFlT0Ff.TO-_vSpUOzHvv73vv73vv73vv70tC--_ve-_ve-_vRMLDgbvv73vv71r77-9EC_vv71OY--_ve-_ve-_ve-_vQ","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6ImNsZWFyc2FmZXR5LTA4ZWI5NjY4LWJhMjctNDRiNC05NzZhLWZhOTE4NDc1NzY1Nl80IiwiZGF0ZSI6MTY3OTMzNDYyMywiZG9tYWluIjoiY2xlYXJzYWZldHkiLCJpYXQiOjE2NzkzMzQ2MjN9.uKb7LTRdXke7lHlvZ1vvxQn8xjIq60LbXDG2bsfaQyc","repositoryName":"clearsafety","routes":[{"type":"page","uid":"index","path":"/"},{"type":"page","path":"/:uid/"},{"type":"blogpost","path":"/news/:uid/"},{"type":"case_study","path":"/case-studies/:uid/"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
